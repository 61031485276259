import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    }
  }), _c('HeadeSearch', {
    attrs: {
      "inputWidth": "370"
    }
  }), _c('div', {
    staticClass: "message-center-wrpa"
  }, [_c('div', {
    staticClass: "message-left"
  }, [_c('div', {
    staticClass: "nav-padding"
  }, [_c('div', {
    staticClass: "nan-message"
  }, [_vm._v("消息中心")]), _vm._l(_vm.navList, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      class: [{
        active: _vm.type === index
      }, 'nav-name'],
      on: {
        "click": function click($event) {
          return _vm.handleNavList(index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "message-right"
  }, [_c('div', {
    staticClass: "message-line"
  }, [_c('div', {
    staticClass: "message-title"
  }, [_c('b-checkbox', {
    attrs: {
      "type": "is-info"
    },
    on: {
      "input": function input($event) {
        return _vm.getSelectAll();
      }
    },
    model: {
      value: _vm.isClickActive,
      callback: function callback($$v) {
        _vm.isClickActive = $$v;
      },
      expression: "isClickActive"
    }
  }, [_vm._v("仅看未读消息")]), _c('b-button', {
    attrs: {
      "size": "is-small"
    },
    on: {
      "click": function click($event) {
        _vm.isShowAllRead = true;
      }
    }
  }, [_vm._v("全部已读")])], 1), _vm._l(_vm.messageList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "message-box"
    }, [_c('div', {
      staticClass: "message-box-title"
    }, [_c('div', {
      staticClass: "message-box-title-left"
    }, [_c('p', [_vm._v(_vm._s(item.name))]), _c('p', [_vm._v(" " + _vm._s(item.subject)), item.readStatus === 0 ? _c('span') : _vm._e()])]), _c('div', {
      staticClass: "message-box-title-right"
    }, [_vm._v(_vm._s(item.createTime))])]), _c('div', {
      staticClass: "message-box-conter"
    }, [_c('div', {
      staticClass: "message-box-left"
    }, [_vm._v(" " + _vm._s(item.content) + " ")]), _c('div', {
      staticClass: "message-box-right",
      on: {
        "click": function click($event) {
          return _vm.handleDetail(item);
        }
      }
    }, [_vm._v(" 查看详情"), _c('i', {
      staticClass: "iconfont icon-arrow-right"
    })])])]);
  })], 2), _vm.messageList.length === 0 ? _c('div', {
    staticClass: "empty"
  }, [_vm._m(0)]) : _vm._e(), _vm.messageList.length != 0 ? _c('div', {
    staticClass: "pagination",
    staticStyle: {
      "width": "918px",
      "justify-content": "flex-end"
    }
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "size": "is-small",
      "total": _vm.total,
      "current": _vm.current,
      "per-page": _vm.perPage,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.current = $event;
      },
      "change": _vm.handleChange
    }
  })], 1) : _vm._e()])]), _c('b-modal', {
    attrs: {
      "scroll": "keep",
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "message-dialog"
        }, [_c('h4', {
          staticClass: "groupTitle"
        }, [_c('span'), _c('button', {
          staticClass: "delete",
          attrs: {
            "type": "button"
          },
          on: {
            "click": props.close
          }
        })]), _c('div', {
          staticClass: "groupCont"
        }, [_vm._v("是否将未读消息置为已读？")]), _c('div', {
          staticClass: "footer"
        }, [_c('b-button', {
          attrs: {
            "type": "concel"
          },
          on: {
            "click": props.close
          }
        }, [_vm._v("取消")]), _c('b-button', {
          attrs: {
            "type": "apply"
          },
          on: {
            "click": function click($event) {
              return _vm.setAllRead();
            }
          }
        }, [_vm._v("确定")])], 1)])];
      }
    }]),
    model: {
      value: _vm.isShowAllRead,
      callback: function callback($$v) {
        _vm.isShowAllRead = $$v;
      },
      expression: "isShowAllRead"
    }
  }), _c('PageFooter')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "empty-list"
  }, [_c('img', {
    attrs: {
      "src": require("./images/pic_empty.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("暂无数据")])]);
}];
export { render, staticRenderFns };