import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import { HeadeSearch } from "@components/head-search";
import { PageFooter } from "@components/page-footer";
import { PageTop } from "@components/page-top";
import { Account } from "@services/account";
import { AccountStorage } from "@services/account";
var account = new Account();
var accountStorage = new AccountStorage();
export default {
  components: {
    HeadeSearch: HeadeSearch,
    PageFooter: PageFooter,
    PageTop: PageTop
  },
  data: function data() {
    return {
      isLogin: false,
      isClickActive: false,
      //是否勾选了
      total: null,
      current: 1,
      // 页码
      perPage: 10,
      rangeBefore: 3,
      rangeAfter: 1,
      type: 0,
      status: "",
      appId: 1,
      // 判断是App还是商城
      messageList: [],
      readStatus: "",
      navList: [{
        id: 1,
        name: "全部"
      }, {
        id: 2,
        name: "订单交易类"
      }, {
        id: 3,
        name: "通知公告类"
      }, {
        id: 4,
        name: "促销活动类"
      }],
      isShowAllRead: false
    };
  },
  computed: {},
  created: function created() {
    this.isLogin = account.isLogined();
    this.getMessageList();
  },
  methods: {
    // 消息列表
    getMessageList: function getMessageList() {
      var _this = this;
      var url = "/xpaas-message-center/api/v1/message/pm/pool/findByApp";
      var data = {
        appId: 1,
        userId: Number(this.getUserId()),
        type: this.status,
        readStatus: this.readStatus,
        page: this.current,
        size: this.perPage,
        companyAccount: Number(this.getAccount())
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        // if (this.type === 0) {
        //   let noticeList = res.data.data.content[0].noticeList;
        //   let orderList = res.data.data.content[0].orderList;
        //   this.messageList = noticeList.concat(orderList);
        // } else {
        _this.messageList = res.data.data.content;
        // }
        _this.total = res.data.data.totalElements;
        _this.perPage = res.data.data.size;
      });
    },
    // 左侧tab
    handleNavList: function handleNavList(index) {
      this.type = index;
      switch (index) {
        case 0:
          this.status = "", this.isClickActive = false;
          this.readStatus = "";
          return this.getMessageList();
        case 1:
          this.status = "1";
          this.isClickActive = false;
          this.readStatus = "";
          this.current = 1;
          return this.getMessageList();
        case 2:
          this.status = "2";
          this.isClickActive = false;
          this.readStatus = "";
          this.current = 1;
          return this.getMessageList();
        case 3:
          this.status = "3";
          this.isClickActive = false;
          this.readStatus = "";
          this.current = 1;
          return this.getMessageList();
      }
    },
    // 已读
    getUpdatePmMessagePool: function getUpdatePmMessagePool(id) {
      var url = "/xpaas-message-center/api/v1/message/pm/pool/updatePmMessagePool?id=".concat(id);
      this.$getHttpClient().get(url).then(function () {});
    },
    //获取用户ID
    getUserId: function getUserId() {
      var userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
      return;
    },
    // 获取公司账号
    loginCompanyAccount: function loginCompanyAccount() {
      var loginCompanyAccount = accountStorage.getCacheUserInfo();
      if (loginCompanyAccount) {
        return loginCompanyAccount.loginCompanyAccount;
      }
      return;
    },
    // 获取account
    getAccount: function getAccount() {
      var account = accountStorage.getCacheUserInfo();
      if (account) {
        return account.account;
      }
      return;
    },
    // 是否勾选未读消息
    getSelectAll: function getSelectAll() {
      if (this.isClickActive) {
        this.readStatus = 0;
        this.getMessageList();
      } else {
        this.readStatus = "";
        this.getMessageList();
      }
    },
    // 分页
    handleChange: function handleChange(val) {
      this.current = val;
      if (this.type === 3) {
        this.getPromotionActivity();
      } else {
        this.getMessageList();
      }
    },
    // 查看详情
    handleDetail: function handleDetail(item) {
      this.getUpdatePmMessagePool(item.id);
      this.$router.push({
        name: "messageCenterDetail",
        params: {
          id: item.id,
          status: item.status
        }
      });
    },
    setAllRead: function setAllRead() {
      var _this2 = this;
      var url = '/xpaas-message-center/api/v1/message/pm/pool/updateStatusAll';
      var data = {
        appId: 1,
        type: this.status,
        companyAccount: Number(this.getAccount())
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        _this2.isShowAllRead = false;
        location.reload();
      });
    }
  }
};