import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    }
  }), _c('HeadeSearch', {
    attrs: {
      "headerTit": "购物车",
      "inputWidth": "370"
    }
  }), _c('div', {
    staticClass: "message-center-wrpa"
  }, [_c('div', {
    staticClass: "message-center-box"
  }, [_c('div', {
    staticClass: "message-title"
  }, [_c('span', {
    on: {
      "click": _vm.handleBack
    }
  }, [_vm._v("<")]), _c('div', {
    staticClass: "message-headline"
  }, [_c('p', [_vm._v(_vm._s(_vm.messageDetail.name))]), _c('p', [_vm._v(_vm._s(_vm.messageDetail.pushTime))])])]), _c('div', {
    staticClass: "message-line"
  }, [_vm._v(" " + _vm._s(_vm.messageDetail.content) + " "), _vm.status == '1006' ? _c('span', {
    staticClass: "message-detail",
    on: {
      "click": function click($event) {
        return _vm.handleBackDetail(_vm.messageDetail);
      }
    }
  }, [_c('span', [_vm._v("，")]), _c('span', {
    staticClass: "title-decoration"
  }, [_vm._v("查看详情")]), _c('span', [_vm._v("！")])]) : _vm._e()])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };