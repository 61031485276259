import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import { HeadeSearch } from "@components/head-search";
import { PageTop } from "@components/page-top";
import { Account } from "@services/account";
var account = new Account();
export default {
  components: {
    HeadeSearch: HeadeSearch,
    PageTop: PageTop
  },
  data: function data() {
    return {
      isLogin: false,
      id: "",
      messageDetail: {},
      status: ""
    };
  },
  computed: {},
  created: function created() {
    this.status = this.$route.params.status;
    this.isLogin = account.isLogined();
    this.id = this.$route.params.id;
    this.getMessageDetail();
  },
  methods: {
    getMessageDetail: function getMessageDetail() {
      var _this = this;
      var url = "/xpaas-message-center/api/v1/message/pm/pool/findById?id=".concat(this.id);
      this.$getHttpClient().get(url).then(function (res) {
        _this.messageDetail = res.data.data;
      });
    },
    handleBack: function handleBack() {
      window.history.go(-1);
    },
    handleBackDetail: function handleBackDetail(v) {
      window.sessionStorage.setItem("totalAmountOwe", JSON.stringify(v));
      this.$router.push({
        name: "debtMessageDetail",
        params: {
          id: v.userUniqueId
        }
      });
    }
  }
};