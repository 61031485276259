<template>
  <div class="page-cart-list-box">
    <!--头部-->
    <PageTop :isLogin="isCurrentLogin"></PageTop>
    <!--头部组件-->
    <HeadeSearch headerTit="购物车" inputWidth="370"></HeadeSearch>
    <!-- 系统提示 -->
    <div class="tips-box" v-if="isShowTips">
      <p>
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        风险客户无法下单！您的资信超期，还款后第二天自动解除限制。
      </p>
      <p>
        <u>去还款</u>
        <img src="@images/close_icon.svg" alt @click="isShowTips = false" />
      </p>
    </div>
    <div class="cart-all-box">
      <!-- 购物车无商品 -->
      <div v-if="isEmpty">
        <div class="empty-data-box">
          <img src="@images/empty_data_icon.svg" alt />
          <p>您还没有添加商品到购物车～</p>
        </div>
      </div>
      <div v-else>
        <div class="cart-top-box">
            <div class="cart-top-box-le">
                <p :style="{'border-bottom':isShowBottom ? '2px solid #eeeeee' : ''}" @click="carTitleChange(1)">全部商品({{ oldCarList.length }})</p>
                <p v-if="abnormalProduct.length > 0" :style="{'border-bottom':!isShowBottom ? '2px solid #eeeeee' : '',
                'background':!isShowBottom ? '#3875F6' : '','color':!isShowBottom ? '#ffffff' : ''}" @click="carTitleChange(0)">库存/价格变动商品({{ abnormalProduct.length }})</p>
                <div class="djs" v-if="countDown.value > 0">
                    <div class="djs-times">
                        <label>本次选购的商品 库存将为您保留半小时</label>
                        <img src="https://obs.pujian.com/frontend/app/order/times.png" alt />
                        <countdown :value="countDown.value" format="h时m分s秒" @stop="countDownStopHandler" />
                    </div>
                </div>
            </div>
          <p @click="cartListInit" class="current-Stock">
            <img src="@images/refresh_icon.svg" alt />
            获取实时库存
          </p>
        </div>
        <!-- 购物车商品展示 -->
        <div class="product-list-box">
          <div class="product-columns">
            <div>
              <p>
                <b-checkbox
                  size="is-small"
                  type="is-info"
                  v-model="selectAll"
                  @input="getSelectAll"
                  >全选</b-checkbox>
              </p>
              <p>商品</p>
            </div>
            <p class="columns-price">单价</p>
            <p class="columns-num">数量</p>
            <p class="columns-subtotal">小计</p>
            <p class="columns-active">操作</p>
          </div>
          <div class="shopp-product-list-box">
            <div
              :class="[
                item.status == 3 ? 'shopp-product-bg' : '',
                'shopp-product'
              ]"
              v-for="(item, index) in cartList"
              :key="index"
            >
              <!-- 这个是禁用复选框，后端暂时不能配合，所以前端暂时去掉禁用的功能 -->
              <!-- :disabled="item.status === '3' || item.status === '4' || item.status === '5'" -->
              <b-checkbox
                type="is-info"
                v-model="item.isCheckedName"
                :disabled="
                  !item.isInvalid || item.status === '4' || item.status === '5'
                "
                @input="getSelectItem($event, item)"
              ></b-checkbox>
              <!--2021-11-15 修改 hy-->
              <b-tooltip position="is-right" multilined type="is-light">
                <div class="product-info" @click="toDetail(item)">
                    <div class="product-info-main">
                    <!-- 商品图片 -->
                    <div class="product-img-box">
                    <div class="product-hbimg-jf" v-if="item.productAttribute == '1'">
                        返利
                    </div>
                    <div class="product-hbimg" v-else-if="item.activityVOList[0] &&item.activityVOList[0].isIntegralCommodity &&item.activityVOList[0].isIntegralCommodity == '1'">
                      <img src="@images/2.gif" />
                    </div>
                    <div class="hospital" v-if="item.hospitalClassificationValue && item.hospitalClassificationValue == '1'">
                        医院
                    </div>
                    <img v-if="item.productPatent && item.productPatent == '1'" class="db_icon" src="https://obs.pujian.com/frontend/app/components/db_icon.png" alt />
                    <!--零差价标识-->
                    <div class="zero-price-img" v-if="item.isZeroPriceDifference == 1">
                      <img src="@images/PC0.png">
                    </div>
                    <div class="product-today" v-if="item.isPurchase">
                      <span>今日已购买</span>
                    </div>
                    <div class="product-img">
                      <img :src="item.picPath" alt />
                    </div>
                    <!-- 已售罄 -->
                    <div class="lose" v-if="item.status == 4">
                      <span>已售罄</span>
                    </div>
                    <!-- 失效商品 -->
                    <div
                      :class="item.kcName === '库存不足' ? '' : 'lose'"
                      v-else-if="
                        item.kcName === '库存不足' ? false : !item.isInvalid
                      "
                                >
                                    <span>失效商品</span>
                                </div>
                                <!-- 库存数量 -->
                                <div
                                        class="lose"
                                        v-else-if="item.status !== '1' && item.status != '4'"
                                >
                                    <span>{{ item.kcName }}</span>
                                </div>
                            </div>
                            <!-- 商品描述 -->
                            <div class="product-introduce">
                                <p class="product-introduce-productName">【{{ item.specifications }}】{{ item.productName }}</p>
                                <div class="" style="display: flex" v-if="item.activityVOList.length > 0 && isOtherAccount==true"
                                     v-for="(activity, acIndex) in item.activityVOList" :key="acIndex">
                                    <ActivityLine activeName="满减"
                                                  v-if="activity.type == 1"
                                                  :type="activity.type"
                                                  bgColor="#FFF3F3"
                                                  borderColor="#FFF3F3"
                                                  color="#000000"
                                                  :isDetail="true"
                                                  :activeContent="activity.activityName"></ActivityLine>
                                    <ActivityLine activeName="返红包"
                                                  v-if="activity.type == 2 || activity.type == 4"
                                                  :type="activity.type"
                                                  bgColor="#FFF5D6"
                                                  borderColor="#FFF5D6"
                                                  color="#000000"
                                                  :isDetail="true"
                                                  :activeContent="activity.activityName"></ActivityLine>
                                </div>
                                <p class="product-introduce-produceFactory">{{ item.produceFactory }}</p>
                                <p v-if="item.nearEffectivePeriod" class="product-introduce-nearEffectivePeriod">
                                    <span>效期</span>
                                    {{ item.nearEffectivePeriod | formatDate }}
                                </p>
                                <p v-else class="product-introduce-nearEffectivePeriod"><span>效期</span></p>
                            </div>
                        </div>
<!--                        <div class="special-offer" v-if="item.activityVOList.length > 0">-->
<!--                            &lt;!&ndash; <p>活动</p> &ndash;&gt;-->
<!--                            <p v-if="item.activityVOList.length > 0 &&item.activityVOList.length < 2">-->
<!--                                活动-->
<!--                            </p>-->
<!--                            <p style="width: 50px"-->
<!--                                    v-else-if="item.activityVOList.length > 1"-->
<!--                            >-->
<!--                                活动▼-->
<!--                            </p>-->
<!--                            &lt;!&ndash; <p>-->
<!--                              {{-->
<!--                                `满${item.numberOne}${item.sellUnit}减${Number(-->
<!--                                  item.moneyOne-->
<!--                                ).toFixed(2)}元`-->
<!--                              }}-->
<!--                            </p> &ndash;&gt;-->
<!--                            <p v-if="item.activityVOList[0].activityName&&item.activityVOList[0].activityZhPrice">-->
<!--                                {{item.activityVOList[0].activityName}}-->
<!--                                &lt;!&ndash; 活动价约¥{{item.activityVOList[0].activityZhPrice}}  &ndash;&gt;-->
<!--                            </p>-->
<!--                            <div-->
<!--                                    class="product-activity-a"-->
<!--                                    v-if="item.activityVOList.length > 1"-->
<!--                            >-->
<!--                                <div-->
<!--                                        class="product-activity-a-t"-->
<!--                                        v-for="(itema, index) in item.activityVOList"-->
<!--                                        :key="index"-->
<!--                                >-->
<!--                                    <div>-->
<!--                                        {{ itema.activityName ? itema.activityName : "" }}-->
<!--                                    </div>-->
<!--                                    &lt;!&ndash; <div>{{itema.activityZhPrice?' 活动价约¥'+itema.activityZhPrice:'' }}</div> &ndash;&gt;-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                <template v-slot:content>
                  <div class="product-tooltip-box">
                    <p class="product-name-box">
            <span :style="{background: item.prescription.bgColor}" v-if="item.prescription && item.prescription.text"
            >{{ item.prescription.text }}</span
            >
                                <font class="product-hbimg-jf" v-if="item.productAttribute == '1'">
                                    返利
                                </font>
                                <img class="tail-hb-img" v-if="item.activityVOList[0]&&item.activityVOList[0].isIntegralCommodity&&item.activityVOList[0].isIntegralCommodity==1" src="@images/2.gif">
                                【{{ item.specifications }}】{{
                                item.productName
                                }}
                            </p>
                    <!-- 价格和优惠活动 -->
                    <div class="product-active-price">
                                <div class="label-box" v-if="item.superDiscountProductPrice">
                                    <p class="label-purchase">特价</p>
                                    <p>
                                        <span class="symbol">¥</span>
                                        <span class="purchase-price">{{Number(item.superDiscountProductPrice) | NumFormat}}
                                        <span v-if="item.sellUnit">{{'/'+item.sellUnit}}</span></span>
                                    </p>
                                    <div class="tj-box" v-if="item.superDiscountProductNum">{{item.superDiscountProductNum}}盒起购</div>
                                </div>
                                <div class="label-box" v-if="item.superDiscountProductPrice">
                                    <p>采购价格</p>
                                    <p style="text-decoration: line-through;">
                                        <span>¥</span>
                                        <span>{{Number(item.preferentialPrice) | NumFormat}}
                                        <span v-if="item.sellUnit">{{'/'+item.sellUnit}}</span></span>
                                    </p>
                                </div>
                                <div class="label-box" v-else>
                                    <p class="label-purchase">采购价格</p>
                                    <p>
                                        <span class="symbol">¥</span>
                                        <span class="purchase-price">{{Number(item.preferentialPrice) | NumFormat}}
                                                <span v-if="item.sellUnit">{{'/'+item.sellUnit}}</span></span>
                                    </p>
                                </div>
                                <div class="label-box">
                                    <p>建议零售价</p>
                                    <p>¥{{ item.suggestedPrice }}</p>
                                </div>
                                <div class="label-box">
                                    <p>毛利率</p>
                                    <p>{{ item.grossProfitRate }}%</p>
                                </div>
                                <div class="label-box" v-if="item.activityVOList.length>0">
                                    <p class="label-active">活动</p>
                                    <div>
                                      <div class="" style="display: flex" v-if="item.activityVOList.length > 0 && isOtherAccount==true"
                                           v-for="(activity, acIndex) in item.activityVOList" :key="acIndex">
                                        <ActivityLine activeName="满减"
                                                      v-if="activity.type == 1"
                                                      :type="activity.type"
                                                      bgColor="#FFF3F3"
                                                      borderColor="#FFF3F3"
                                                      color="#000000"
                                                      :isDetail="true"
                                                      :activeContent="activity.activityName" />
                                        <ActivityLine activeName="返红包"
                                                      v-if="activity.type == 2 || activity.type == 4"
                                                      :type="activity.type"
                                                      bgColor="#FFF5D6"
                                                      borderColor="#FFF5D6"
                                                      color="#000000"
                                                      :isDetail="true"
                                                      :activeContent="activity.activityName" />
                                      </div>
                                    </div>
                                </div>
                            </div>
                    <div class="product-info-basic-box">
                                <ul>
                                    <li>
                                        <p>批准文号</p>
                                        <p>{{ item.approvalNumber }}</p>
                                    </li>
                                    <li>
                                        <p>剂型</p>
                                        <p>{{ item.formulation }}</p>
                                    </li>
                                    <li>
                                        <p>包装</p>
                                        <p>{{ item.sellUnit }}</p>
                                    </li>
                                    <li>
                                        <p>件装</p>
                                        <p>
                                            {{ item.packageNumber }}
                                        </p>
                                    </li>
                                    <li>
                                        <p>生产厂家</p>
                                        <p>{{ item.produceFactory | ellipsisss }}</p>
                                    </li>
                                    <li v-if="item.produceAddress">
                                        <p>产地</p>
                                        <p>{{ item.produceAddress}}</p>
                                    </li>
                                    <li>
                                        <p>处方分类</p>
                                        <p>
                                            <!-- <span
                                              v-if="
                                                pageDataSource.prescription &&
                                                  pageDataSource.prescription.type
                                              "
                                              >{{ pageDataSource.prescription }}</span
                                            > -->
                                            <span v-if="item.prescription">
                    {{ item.prescription.type }}
                    {{ item.prescription.text }}
                  </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>效期</p>
                                        <p v-if="item.nearEffectivePeriod">
                                            {{ item.nearEffectivePeriod | formatDate }}
                                        </p>
                                        <p v-else></p>
                                    </li>
                                    <li v-if="Number(item.isMediumPackage) === 1">
                                        <p>中包装数量</p>
                                        <p>{{ item.mediumPackage }}</p>
                                    </li>
                                </ul>
                            </div>
                  </div>
                </template>
              </b-tooltip>


              <!-- 商品价格 -->
              <div class="product-price" @click="toDetail(item)"  v-if="item.isAbnormal != 1">
                <p class="tj-product-price" v-if="item.superDiscountProductPrice">
                    <span>特价</span>
                    <span>
                       {{ Number(item.superDiscountProductPrice) | NumFormat }}
                       <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                    </span>
                </p>
                <div class="tj-payNum" v-if="item.superDiscountProductNum"><div class="tj-payNum-box">{{item.superDiscountProductNum}}盒起购</div></div>
                <p class="tj-oldPrice" v-if="item.superDiscountProductPrice">
                    {{ Number(item.preferentialPrice) | NumFormat }}
                    <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                </p>
                <p v-else>
                  {{ Number(item.preferentialPrice) | NumFormat }}
                  <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                </p>
                <!-- <s>¥ {{ item.unitPrice | Format }}</s> -->
              </div>
                <!--异常订单商品-->
              <div class="product-price" @click="toDetail(item)"  v-if="item.isAbnormal == 1">
                  <div class="product-price-now">
                      <img v-if="(Number(item.oldprice)-Number(item.unitPrices)) < 0" src="../../../assets/images/up.png" alt />
                      <img v-if="(Number(item.oldprice)-Number(item.unitPrices)) > 0" src="../../../assets/images/down.png" alt />
                      {{ Number(item.preferentialPrice) | NumFormat }}<span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                  </div>
                  <del v-if="item.isAbnormal == 1 && ((Number(item.oldprice)-Number(item.preferentialPrice)) > 0 || (Number(item.oldprice)-Number(item.preferentialPrice)) < 0)">原价¥{{Number(item.oldprice)}}</del>
              </div>
              <!-- 商品数量 -->
              <div class="product-num">
                <div>
                  <!-- <p
                    v-if="Number(item.isMediumPackage) === 1"
                    class="medium-packaging"
                  >
                    中包装（购买数量
                    <span>{{ item.totalNumber * item.mediumPackage }}</span> ）
                  </p> -->
                  <!-- <b-field
                    :message="
                      item.kcNumber === 0
                        ? `库存${item.kcNumber}`
                        : item.totalNumber === 0
                        ? '数量必须大于或等于 1'
                        : item.totalNumber > item.kcNumber
                        ? `数量必须小于或等于 ${item.kcNumber}`
                        : ''
                    "
                  >
                    <b-numberinput
                      size="is-small"
                      v-model="item.totalNumber"
                      @blur="onBlur(item, index)"
                      min="1"
                      placeholder="1"
                      :max="maxkcNumber(item)"
                      @input="input(item)"
                    ></b-numberinput>
                  </b-field> -->

                  <div class="inventory-wrap-top" v-if="item.kcNumber != 0">
                    <p class="inventory-wrap" v-if="item.status === '1'">
                      库存{{ item.kcNumber > 99 ? "99+" : item.kcNumber }}
                    </p>
                    <p v-else :class="item.kcName == '库存不足' ? 'inventory-color' : ''">
                      {{ item.kcName }}
                    </p>
                  </div>
                  <NumberInput
                    :list="item"
                    @handleMinus="handleMinus(item)"
                    @handleSign="handleSign(item)">
                    <template slot="top">
                      <p class="medium-packaging" v-if="Number(item.isMediumPackage) === 1">
                        <span>中包装(1中包装={{ item.mediumPackage }}{{ item.sellUnit }})</span>
                      </p>
                    </template>
                    <template slot="dome">
                      <p class="medium-packaging" v-if="Number(item.isMediumPackage) === 1">
                        <span>实际购买
                          {{ Number(item.totalNumber) * item.mediumPackage }}{{ item.sellUnit }}</span>
                      </p>
                    </template>
                  </NumberInput>
                  <div class="tj-details" v-if="item.superDiscountProductPrice && item.superDiscountProductNum">
                    <div class="tj-details-line">
                        <span>￥{{item.superDiscountProductPrice}}(特价)</span>
                        <span>x{{item.superDiscountProductNum}}</span>
                    </div>
                      <div class="tj-details-line" v-if="item.oldNum">
                          <span>￥{{item.preferentialPrice}}</span>
                          <span>x{{item.oldNum}}</span>
                      </div>
                  </div>
                  <!--异常订单库存不足的商品提示用户库存不足-->
                  <div class="yc-product-msg" v-if="item.isAbnormal == 1 && Number(item.oldqty) - Number(item.availableNum) > 0">
                    原订单{{item.oldqty}}盒，仅剩{{ item.availableNum }}{{ item.sellUnit }}
                  </div>
                  <!-- <div class="inventory-lose">
                    <p>失效商品</p>
                  </div> -->
                </div>
              </div>
              <!-- 小计金额 -->
              <div class="product-subtotal">
                <p>¥ {{ Number(item.subtotalMoney) | NumFormat }}</p>
                <p v-if="item.reducedPrice">
                  优惠<span> ¥{{ Number(item.reducedPrice) | NumFormat }}</span>
                </p>
              </div>
              <!-- 操作栏 -->
              <div class="product-active">
                <!-- <p>移入收藏夹</p> -->
                <p @click="delCartProduct(item)">删除</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 结算操作栏 -->
        <div :class="[{ 'scroll-bottom': isFixed }, 'settlement-active-box']">
          <div
            class="cart-activity"
            v-if="Number(cartInfo.allTotalMoney) > 0 && showpa">
            <div class="cart-activity-r" @click="goMakebill" v-if="Number(cartInfo.allTotalMoney) > 0 && Number(cartInfo.isMax) < 0">
              去凑单 >
            </div>
            <div class="cart-activity-l">
              <div class="cart-activity-l-t">
                <template>
                  当前订单¥{{ cartInfo.allTotalMoney }}，
                </template>
                <template>
                  满减优惠¥{{ cartInfo.ticketPriceTotal == "" ? 0 : cartInfo.ticketPriceTotal == null ? 0:cartInfo.ticketPriceTotal}}，
                </template>
                <div v-if="cartInfo.allTotalMoney<299" style="font-size: 20px;">
                  <template>
                      满¥299参与返利活动，最高享整单7%返利
                  </template>
                </div>
                <div v-else style="font-size: 20px;">
                    <template v-if="Number(cartInfo.allTotalMoney) > 0 && Number(cartInfo.isMax) < 0">
                        再凑¥{{ cartInfo.amountGap }}元返利品种，
                    </template>
                    <template v-if="Number(cartInfo.allTotalMoney) > 0 && Number(cartInfo.isMax) == 0">
                        再凑¥{{ cartInfo.amountGap }}元返利品种，
                    </template>
                    <template v-if="Number(cartInfo.allTotalMoney) > 0 &&Number(cartInfo.isMax) == 1">
                        添加更多返利品种，享更多返利优惠
                    </template>
                    <template v-else>
                        享整单{{ cartInfo.proportion | proportionFilter }}%返利
                    </template>
                </div>
<!--                <template v-if="Number(cartInfo.isMax) > -1">-->
<!--                  可获得奖励{{ cartInfo.giftAmount }}元。支付后两日到账。-->
<!--                </template>-->
<!--                <template-->
<!--                  v-if="-->
<!--                    Number(cartInfo.allTotalMoney) > 0 &&-->
<!--                    Number(cartInfo.isMax) < 0-->
<!--                  "-->
<!--                >-->
<!--                  可参与红包返利活动-->
<!--                </template>-->
                <div class="ad_tips_icon" @click="showrule">返利规则</div>
              </div>
            </div>
          </div>
          <div class="left-box">
            <p>
              <b-checkbox
                size="is-small"
                type="is-info"
                v-model="selectAll"
                @input="getSelectAll"
                >全选</b-checkbox
              >
            </p>
            <p @click="delCartProduct('selected')">删除所选</p>
            <!-- <p>移入收藏夹</p> -->
            <p @click="delInvalid">清空无效商品</p>
          </div>
          <div class="right-box">
            <p>
              已选商品
              <span class="choice">
                <span>{{ cartInfo.productNum }}</span>件
              </span>
            </p>
            <p>活动价 ¥
              <span class="total-amount">{{ Number(cartInfo.productSumPrice) | NumFormat }}</span>
            </p>
            <p v-if="cartInfo.isMax<0">满减优惠¥<span class="choice">{{ Number(cartInfo.ticketPriceTotal) | NumFormat }}</span></p>
            <p v-if="cartInfo.isMax>-1">返利¥<span class="choice">{{ Number(cartInfo.giftAmount) | NumFormat }}</span>(次日到账)</p>
            <div class="buttons">
              <b-button
                :class="[
                  {
                    'bg-submit-button':
                      !hasChecked || isShowTips || isChecked !== 0 || isNum
                  },
                  'submit-button'
                ]"
                @click="showMediumPackage()"
                :disabled="
                  !hasChecked ||
                  isShowTips ||
                  isChecked !== 0 ||
                  isNum ||
                  disSubmit
                "
                >确认订单</b-button
              >
            </div>
          </div>
        </div>
        <div :class="{ 'scroll-bg-bottom': isFixed }"></div>
      </div>
    </div>
    <b-modal
      v-model="isShowMediumPackage"
      scroll="keep"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default="props">
        <div class="inner-dialog">
          <h4 class="groupTitle">
            <span></span>
            <button type="button" class="delete" @click="props.close" />
          </h4>
          <div class="groupCont">
            <div
              class="mpList"
              v-for="item in mediumPackageList"
              :key="item.productId"
            >
              <div class="topInfo">
                <p class="productName">{{ item.productName }}</p>
                <p>{{ item.specifications }}</p>
                <p>
                  ¥ {{ Number(item.preferentialPrice) | NumFormat }}
                  <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                </p>
                <p class="factory">{{ item.produceFactory }}</p>
              </div>
              <div class="mpInfo">
                <p>
                  购买 <span>{{ item.totalNumber }}</span> 个中包装
                </p>
                <p>
                  实际购买
                  <span>{{ Number(item.totalNumber) * item.mediumPackage }}</span>{{ item.sellUnit }}
                </p>
                <p>
                  小计¥
                  <span>{{ Number(item.subtotalMoney) | NumFormat }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="footer">
            <b-button type="concel" @click="props.close">取消</b-button>
            <b-button type="apply" @click="toSubmit"> 确定 </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <!--欠款提示-->
    <b-modal
      v-model="userArrears"
      trap-focus
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      :can-cancel="true"
      :has-modal-card="true"
      :destroy-on-hide="true">
      <template #default="props">
          <div class="arrears-dialog" style="border-radius: 10px">
              <div class="proBox1">
                  <div class="proBox-title">
                      <img src="../../../assets/images/img_adorn.png" alt="" />
                      <span v-if="arrearsData.days == 0 && arrearsData.haveOtherDebit == 1 && arrearsData.isLock === 0">明天将因欠款无法下单</span>
                      <span v-if="arrearsData.isLock === 1">因欠款无法下单</span>
                  </div>
                  <div class="proBox-msg">
                      <p>截止当前，<span style="color: #E5432E">您已累计欠款{{arrearsData.currentSumDebit}}元，</span></p>
                      <p>为不影响您正常下单，请立即还款。</p>
                  </div>
                  <div class="proBox-btn" @click="nowArrears">
                      <span>立即还款</span>
                  </div>
              </div>
          </div>
      </template>
    </b-modal>
    <PageFooter ref="footertop"></PageFooter>

    <div class="fd-rules" v-if="isshowrule" @click.stop="closerule">
      <div class="fd-rule" @click.stop="showrule">
        <div class="fd-close" @click.stop="closerule">
          <img src="@/assets/images/close_icon.svg" alt="" />
        </div>

        <template v-if="isshowqy && isshowrule">
          <div class="fd-rule2">
<!--              托运客户购买商品总金额不足299元不能下单<br />-->
              暂时无法提交，下单金额需满足299元<br />
          </div>
          <div class="fd-rule1">一、适用对象</div>
          <div class="fd-rule2">配送方式为托运的客户<br /></div>
          <div class="fd-rule1">二、启运金额</div>
          <div class="fd-rule2">订单金额≥299元; <br /></div>
          <div class="fd-rule1">三、启运时间</div>
          <div class="fd-rule2">
<!--              前一日16:00到当天16:00下单,当天发出托运。(注:该时间段,若已有订单金额≥299元,追加订单不受订单金额影响,均可下单;且可在当天可发托运)<br />-->
              每天16点之前下的订单，当天发出托运。<br />
          </div>
        </template>
        <template v-else-if="isshowrule && isshowerr">
          <div class="fd-rule2">{{ errmsg }}<br /></div>
        </template>
        <!--修改返利规则同步app规则-->
        <template v-else>
          <div class="fd-rule1">返利规则</div>
          <div class="fd-rule2" style="margin-bottom: 0;padding: 10px 0">
              单笔订单满299元，可享整单返利3%-7%.选购“返利”标签的品种越多，返利越多。次日到账。
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
var _this ;
import { HeadeSearch } from "@components/head-search";
import { PageFooter } from "@components/page-footer";
import { AccountStorage } from "@services/account";
import { PageTop } from "@components/page-top";
import { Account } from "@services/account";
import { NumberInput } from "@components/number-input";
import { Countdown } from "@components/countdown";
import { ActivityLine } from "@components/activity-line";
// 新增缓存
const savePreOrder =
  "/pjyy-deepexi-order-center/api/v1/order/OcOrder/savePreOrder";
const account = new Account();
const accountStorage = new AccountStorage();
var timer;
export default {
  components: {
    HeadeSearch,
    PageFooter,
    PageTop,
    NumberInput,
    Countdown,
    ActivityLine
  },
  data() {
    return {
      isshowrule: false,
      mediumPackageList: [],
      isShowMediumPackage: false, //显示中包装弹窗
      // hasChecked: false,
      isFixed: false,
      messages: "",
      cartList: [],
      oldCarList:[],
      isshowqy: false,
      cartInfo: {
        productNum: 0,
        totalMoney: 0,
        productSumPrice:0,//新商品总价
        reducedPriceTotal: 0,
        amountGap: "",
        proportion: "",
        giftAmount: "",
        allTotalMoney: "",
        isMax: -1,
        ticketPriceTotal:""
      },
      errmsg: "",
      isshowerr: false,
      disSubmit: false,
      mapResultData: {},
      selectAll: false, //是否全选
      isShowTips: false,//是否显示系统提示
      showpa:false,
      cashAmount:null,
      isScrollTo:null,
      isExternal:false,
      countDown: {
        show: false,
        value: 0
      },
      abnormalProduct:[],//异常订单
      isShowBottom:true,
      userArrears:false,//欠款提示
      arrearsData:{},//欠款信息
      isOtherAccount:true
      // ischeckNum: 0,
      // num: null
    };
  },
  computed: {
    isEmpty() {
      return this.cartList.length > 0 ? false : true;
    },
    // 是否禁用提交按钮
    hasChecked() {
      return this.cartList.some(item => {
        // this.ischeckNum = item.isCheckedName
        return item.isCheckedName === true;
      });
    },
    // 实时校验勾中的数据，库存不足需禁用确认订单按钮
    isChecked() {
      let state = 0;
      this.cartList.forEach(item => {
        if (
          (Number(item.status) === 3 ||
            Number(item.status) === 4 ||
            Number(item.status) === 5) &&
          item.isCheckedName
        ) {
          state++;
        }
      });
      return state;
    },
    // 全选
    // selectAll() {
    //   let arr = [];
    //   this.cartList.forEach(item => {
    //     if (item.status !== "3" && item.status !== "4" && item.status !== "5") {
    //       arr.push(item);
    //     }
    //   });
    //   return arr.every(item => {
    //     return item.isCheckedName === true;
    //   });
    // },
    isCurrentLogin() {
      //获取登录状态
      return account.isLogined();
    },
    // 当数量为 0 不能点击确认订单
    isNum() {
      // return this.cartList.some(item => {
      //   return item.totalNumber === 0;
      // });
      let a = [];
      this.cartList.forEach(item => {
        if (item.isCheckedName === true) {
          a.push(item);
        }
      });
      return a.some(it => {
        return Number(it.totalNumber) === 0;
      });
    },
    // 失效商品不能下单
    isLose() {
      let a = [];
      this.cartList.forEach(item => {
        if (!item.isInvalid && item.isCheckedName) {
          a.push(item);
        }
      });
      return a.some(it => {
        return true;
      });
    }
  },
  beforeCreate(){
      _this = this;
  },
  filters: {
    proportionFilter: function(value) {
        let res = 0
        let current = 0
        if (_this.cartInfo.isMax == '0') {
            current = Number(value) + 10
        } else {
            current = value
        }
        console.log("current", current)
        switch (Math.ceil(Number(current) / 10)) {
            case 3:
                res = 3;
                break;
            case 4:
                res = 4;
                break;
            case 5:
                res = 5;
                break;
            case 6:
                res = 6;
                break;
            case 7:
                res = 7;
                break;

        }
        console.log(res)
        return res
    }
  },
  async created() {
    this.isScrollTo = this.$route.params.isScrollTo;
    this.arr = [];
    await this.cartListInit();
    window.addEventListener("scroll", this.initHeight);
    if (this.cartList.length != 0) {
      this.productHeight();
    }
    this.getShowactivity();
    this.getArrears();
    // this.getShowactivity()
    //await this.getCurrentStock();
  },
  watch: {
    cartList: {
      handler() {
        let arr = [];
        this.cartList.forEach(item => {
          if (
            item.status !== "3" &&
            item.status !== "4" &&
            item.status !== "5"
          ) {
            arr.push(item);
          }
        });
        this.selectAll = arr.every(item => {
          return item.isCheckedName === true;
        });
      },
      deep: true
    }
  },
  mounted(){
    let userInfo = accountStorage.getCacheUserInfo();
    let userId = userInfo.account;
    if(userId.charAt(0)=='8') {
      this.isOtherAccount = true;
    }
    if(userInfo){
      this.isExternal = userInfo.type == 1 ? true : false
    }
  },
  methods: {
    //获取欠款
    async getArrears(){
      let account = accountStorage.getCacheUserInfo().account;
      const URL = `/pjyy-deepexi-erp-api-center/api/v1/erp/creditStanding/pc/${account}`;
      const result = await this.$getHttpClient().get(URL);
      if (result.data.code == 200){
        this.arrearsData = result.data.data;
        if(this.arrearsData.show){
          if(!this.arrearsData.cashType) {
            if (this.arrearsData.days == 0 || this.arrearsData.isLock === 1) {
              this.userArrears = true
            }
          }
        }
      }
    },
    //立即还款
    async nowArrears(){
      let account = accountStorage.getCacheUserInfo().account;
      const URL = `/pjyy-deepexi-pay-center/rpc/v2/openPay/getWebPayLink1?erpId=${account}&orderSource=5`;
      const result = await this.$getHttpClient().get(URL);
      if (result.data.code == 200){
        window.open(result.data.data)
      }
    },
    //标题点击切换
    carTitleChange(val){
      if (val === 1){
        this.cartList = this.oldCarList;
        this.isShowBottom = true;
      }else{
        this.cartList = this.abnormalProduct;
        this.isShowBottom = false;
      }
    },
    // 倒计时停止跳转到订单列表
    countDownStopHandler() {
        this.abnormalProduct = [];
        this.isShowBottom = true;
        this.countDown.value = 0
    },
    async getShowactivity() {
      // 是否显示凑单提示
      const res = await this.$getHttpClient().get(
        "/pjyy-deepexi-order-center/api/v1/cart/updateDisplay"
      );
      if (res.data.code == "200") {
        if (res.data.data == "1") {
          this.showpa = true;
        }
      }
    },
    goMakebill() {
      this.$router.push({
        path: "/product/makebill"
      });
    },
    closerule() {
      this.isshowrule = false;
      this.isshowqy = false;
      this.isshowqy = false;
    },
    showrule() {
      this.isshowrule = true;
    },
    productHeight() {
      let clientHeight = document.documentElement.clientHeight;
      let scrollY = document.documentElement.scrollTop;
      var top = this.$refs.footertop && this.$refs.footertop.$el.offsetTop;
      if (scrollY + clientHeight > top) {
        this.isFixed = false;
      } else {
        this.isFixed = true;
      }
    },
    initHeight() {
      let _this = this;
      let clientHeight = document.documentElement.clientHeight;
      let scrollY = document.documentElement.scrollTop;
      var top = _this.$refs.footertop && _this.$refs.footertop.$el.offsetTop;
      if (scrollY + clientHeight > top) {
        _this.isFixed = false;
      } else {
        _this.isFixed = true;
      }
    },
    // 判断数量++的最大值
    maxkcNumber(val) {
      let num = null;
      if ((val.totalNumber + "").length >= 6) {
        return (val.totalNumber = 9999);
      }
      if (Number(val.isMediumPackage) === 1) {
        num = Number(val.kcNumber) / Number(val.mediumPackage);
      } else {
        num = val.kcNumber;
      }
      return parseInt(num);
    },
    toDetail(val) {
      this.$router.push({
        name: "details",
        params: {
          id: val.productId,
          sale: val.notLowerSale
        }
      });
    },
    blurNum() {},
    // 清空无效商品
    async delInvalid() {
      const _this = this;
      this.$buefy.dialog.confirm({
        title: "提示",
        message: "确认是否将无效商品移出购物车？",
        confirmText: "确定",
        cancelText: "取消",
        type: "is-info",
        onConfirm: async function () {
          const URL = `/pjyy-deepexi-order-center/api/v1/cart/emptyProduct?userId=${_this.getUserId()}`;
          const result = await this.$getHttpClient().get(URL);
          if (result.data.code === "200") {
            await _this.cartListInit();
            this.$buefy.toast.open({
              message: "删除无效商品成功",
              type: "is-success"
            });
          }
        }
      });
    },
    //删除购物车
    async delCartProduct(val) {
      let productId = [];
      if (val === "selected") {
        this.cartList.forEach(item => {
          if (item.isChecked) {
            productId.push(item.productId);
          }
        });
      } else {
        productId = [val.productId];
      }
      if (productId.length !== 0) {
        const _this = this;
        this.$buefy.dialog.confirm({
          title: "提示",
          message: "确认是否将选中商品移出购物车？",
          confirmText: "确定",
          cancelText: "取消",
          type: "is-info",
          onConfirm: async function () {
            const params = {
              productId,
              userId: _this.getUserId()
            };
            const URL = "/pjyy-deepexi-order-center/api/v1/cart/removeCartList";
            const result = await _this.$getHttpClient().post(URL, params);
            if (result && result.data && result.data.code === "200") {
              await _this.cartListInit();
              this.$buefy.toast.open({
                message: "删除成功",
                type: "is-success"
              });
              _this.productHeight();
            }
          }
        });
      }
    },
    // ----
    async handleMinus(item) {
      if (item.totalNumber === 0) {
        return;
      }
      const params = {
        totalNumber: item.totalNumber,
        productId: [item.productId],
        code: 1
      };
      this.arr = [];
      const result = await this.cartListDataSource(params);
      if (result) {
        this.abnormalProduct = [];
        this.isShowBottom = true;
        this.cartList = result.ocCartLists;
        this.oldCarList = result.ocCartLists;
        for (let i in this.cartList){
          if(this.cartList[i].isAbnormal == 1){
            this.abnormalProduct.push(this.cartList[i])
          }
        }
      }
    },
    // +++
    async handleSign(item) {
      const params = {
        totalNumber: item.totalNumber,
        productId: [item.productId],
        code: 1
      };
      this.arr = [];
      const result = await this.cartListDataSource(params);
      if (result) {
        this.abnormalProduct = [];
        this.isShowBottom = true;
        this.cartList = result.ocCartLists;
        this.oldCarList = result.ocCartLists;
        for (let i in this.cartList){
          if(this.cartList[i].isAbnormal == 1){
            this.abnormalProduct.push(this.cartList[i])
          }
        }
      }
    },
    async input(item) {
      const params = {
        totalNumber: item.totalNumber,
        productId: [item.productId],
        code: 1
      };
      this.arr = [];
      // 当购物车数量太大时 不要请求接口
      if (item.totalNumber > 9999 || item.totalNumber === 0) {
        return;
      }
      const result = await this.cartListDataSource(params);
      if (result) {
        this.cartList = result.ocCartLists;
      }
    },
    //获取用户ID
    getUserId() {
      let userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
      return;
    },
    //购物车商品列表接口数据
    async cartListDataSource(params = {}) {
      // let _default = {
      //   userId: Number(this.getUserId())
      // };
      // _default = Object.assign(_default, params);
      const URL = "/pjyy-deepexi-order-center/api/v1/cart/findCartList";
      const result = await this.$getHttpClient().post(URL, params);
      if (result && result.data && result.data.code === "200") {
        this.disSubmit = false;
        const resultData = result.data.data;
        this.cartInfo.totalMoney = resultData.totalMoney;
        this.cartInfo.productSumPrice = resultData.productSumPrice;
        this.cartInfo.productNum = resultData.productNum;
        this.cartInfo.reducedPriceTotal = resultData.reducedPriceTotal;
        this.cashAmount = resultData.cashAmount;
        this.cartInfo.amountGap = resultData.amountGap;
        this.cartInfo.proportion = resultData.proportion;
        this.cartInfo.giftAmount = resultData.giftAmount;
        this.cartInfo.allTotalMoney = resultData.allTotalMoney;
        this.cartInfo.isMax = resultData.isMax;
        this.countDown.value = resultData.lockTime;
        this.cartInfo.ticketPriceTotal = resultData.ticketPriceTotal;
        return resultData;
      }
    },
    //刷新库存
    async cartListInit() {
      this.arr = [];
      const result = await this.cartListDataSource();
      if (result) {
        this.cartList = result.ocCartLists;
        this.oldCarList = result.ocCartLists;
        this.abnormalProduct = [];
        for (let i in this.cartList){
          if(this.cartList[i].isAbnormal == 1){
            this.abnormalProduct.push(this.cartList[i])
          }
        }
        if (this.isScrollTo == 1 && this.abnormalProduct.length > 0){
          this.cartList = this.abnormalProduct;
          this.isShowBottom = false;
        }else{
          this.isShowBottom = true;
        }
      }
    },
    // 提交订单
    toSubmit() {
      if (timer) {
        clearInterval(timer);
      }
      this.disSubmit = true;
      if (this.isLose) {
        this.$buefy.toast.open({
          message: "所选商品中包含失效商品，不能提交！",
          type: "is-danger"
        });
        return;
      }
      this.$getHttpClient({
        useResponseToast: false
      })
        .post(savePreOrder,{
            cashAmount:this.cashAmount
        })
        .then(res => {
          this.isShowMediumPackage = false;
          if (res.data.code === "200") {
            this.$router.push({
              name: "orderConfirm",
              params: {
                id: res.data.data
              }
            });
          } else if (res.data.code === "100000") {
            this.isShowTips = true;
          } else {
            if (res.data.msg == "9999") {
              this.isshowqy = true;
              this.isshowrule = true;
            } else {
              this.errmsg = res.data.msg;
              this.isshowrule = true;
              this.isshowerr = true;
            }
          }
          let th = this;
          timer = setInterval(() => {
            th.disSubmit = false;
          }, 5000);
        });
    },
    onBlur(item, index) {
      if (item.totalNumber === 1) {
        this.$nextTick(() => {
          this.cartList[index].totalNumber = 1;
        });
      }
    },
    // 全选
    async getSelectAll(val) {
      this.selectAll = val;
      let isChecked = val ? 1 : 0;
      let productId = [];
      this.cartList.forEach(item => {
          if (item.status !== "3" && item.status !== "4" && item.status !== "5" && item.isInvalid) {
              productId.push(item.productId);
          }
      });
      const params = {
        isChecked,
        productId,
        code: 3
      };
      const result = await this.cartListDataSource(params);
      if (result) {
        this.abnormalProduct = [];
        this.isShowBottom = true;
        this.cartList = result.ocCartLists;
        this.oldCarList = result.ocCartLists;
        for (let i in this.cartList){
          if(this.cartList[i].isAbnormal == 1){
            this.abnormalProduct.push(this.cartList[i])
          }
        }
      }
    },
    // 单个勾选
    async getSelectItem($event, val) {
      let isChecked = val.isCheckedName ? 1 : 0;
      // this.ischeckNum = isChecked
      const params = {
        productId: [val.productId],
        isChecked,
        code: 2
      };
      const result = await this.cartListDataSource(params);
      if (result) {
        this.abnormalProduct = [];
        this.isShowBottom = true;
        this.cartList = result.ocCartLists;
        this.oldCarList = result.ocCartLists;
        for (let i in this.cartList){
          if(this.cartList[i].isAbnormal == 1){
            this.abnormalProduct.push(this.cartList[i])
          }
        }
      }
    },
    showMediumPackage() {
        this.$buefy.dialog.confirm({
            title: "提示",
            message: "当前系统版本已经停止维护，请前往新版本进行下单！或点击页面顶部的新版入口前往。",
            confirmText: "立即前往",
            cancelText: "我知道了",
            type: "is-info",
            onConfirm: async function () {
              window.open("https://pujian.com/v2/shopcard")
            },
            onCancel: async function () {
              window.open("https://pujian.com/v2/shopcard")
            }
        });
        return
      let arr = [];
      this.cartList.forEach(item => {
        if (item.isCheckedName && item.isMediumPackage == 1) {
          arr.push(item);
        }
      });
      if (arr.length > 0) {
        this.mediumPackageList = arr;
        this.isShowMediumPackage = true;
      } else {
        this.toSubmit();
      }
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.initHeight, false);
  }
};
</script>
<style src="./cart-list.less" lang="less">
.cart-activity {
  width: 100%;
  background: #fee9cc;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
</style>
